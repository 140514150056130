<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <b-row>
              <b-col>
                <b-form-select
                  v-model="student_class_selected"
                  :options="student_classes"
                  @change="getClassSubjectsList"
                ></b-form-select>
              </b-col>
            </b-row>
            <br /><br />
            <b-row>
              <b-table
                striped
                hover
                show-empty
                :items="curriculum_subjects"
                :fields="fields"
                :busy="isBusy"
                class="mt-3"
              >
                <template v-slot:empty="scope">
                  <p class="text-center">
                    {{ $t("GENERAL.NO_SUBJECTS") }}
                  </p>
                </template>
                <template v-slot:cell(actions)="{ item }">
                  <b-button
                    block
                    variant="dark"
                    class="mt-3 pb-5"
                    v-b-tooltip.hover
                    :title="$t('QUIZ.OPEN_QUIZ')"
                    :to="{
                      name: 'subjectQuizList',
                      params: {
                        class_id: item.class_id,
                        subject_id: item.value
                      }
                    }"
                  >
                    <i
                      class="fa fa-book float-left"
                      style="font-size: 1.3em; margin-left: 0; color: white"
                    ></i>
                    <i
                      class="fa fa-long-arrow-alt-left float-left"
                      style="font-size: 1.3em; margin-left: 0; color: white"
                    >
                    </i>
                    &nbsp;&nbsp;&nbsp;
                    <span
                      class="font-weight-bold float-left"
                      style="color: white"
                    >
                      {{ item.text }}</span
                    >
                  </b-button>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                  </div>
                </template>
              </b-table>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "Quiz",
  data() {
    return {
      errors: [],
      title: this.$t("MENU.QUIZ"),
      isBusy: true,
      fields: [{ actions: this.$t("GENERAL.SUBJECTS") }],
      student_classes: [],
      student_class_selected: 0,
      curriculum_subjects: []
    };
  },
  components: {
    KTPortlet
  },
  methods: {
    getClassSubjectsList() {
      ApiService.get(
        "student/enrolled-class-subjects/" + this.student_class_selected
      ).then(({ data }) => {
        this.curriculum_subjects = data;
        this.isBusy = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.QUIZ"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/enrolled-classes/exam-results").then(({ data }) => {
      this.student_classes = data.student_classes;
      this.student_class_selected =
        data.student_classes[0] !== undefined
          ? this.student_classes[0].value
          : 0;
      this.getClassSubjectsList();
    });
  }
};
</script>
