var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('KTPortlet',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.student_classes},on:{"change":_vm.getClassSubjectsList},model:{value:(_vm.student_class_selected),callback:function ($$v) {_vm.student_class_selected=$$v},expression:"student_class_selected"}})],1)],1),_c('br'),_c('br'),_c('b-row',[_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","show-empty":"","items":_vm.curriculum_subjects,"fields":_vm.fields,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.NO_SUBJECTS"))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mt-3 pb-5",attrs:{"block":"","variant":"dark","title":_vm.$t('QUIZ.OPEN_QUIZ'),"to":{
                    name: 'subjectQuizList',
                    params: {
                      class_id: item.class_id,
                      subject_id: item.value
                    }
                  }}},[_c('i',{staticClass:"fa fa-book float-left",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}}),_c('i',{staticClass:"fa fa-long-arrow-alt-left float-left",staticStyle:{"font-size":"1.3em","margin-left":"0","color":"white"}}),_vm._v(" "),_c('span',{staticClass:"font-weight-bold float-left",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(item.text))])])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(_vm._s(_vm.$t("GENERAL.PLEASE_WAIT")))])],1)]},proxy:true}])})],1)]},proxy:true}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }